<template>
  <v-scale-transition>
    <wide-panel
      :title="title"
    >
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        />
        <v-spacer />
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="date"
              prepend-icon="mdi-calendar-outline"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="date"
            min="2020-11-01"
            :max="today"
            no-title
            @input="dateMenu = false"
          />
        </v-menu>
      </v-toolbar>
      <v-layout>
        <v-flex xs3>
          <v-subheader>Activations</v-subheader>
        </v-flex>
        <v-flex
          xs3
          offset-xs5
        >
          <v-text-field
            v-model="searchActivation"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-flex>
        <v-flex xs1>
          <v-btn
            dark
            fab
            small
            color="light-green"
            @click="exportReport(activations)"
          >
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-data-table
        :headers="activationHeaders"
        :items="activations"
        :expand="expand"
        :loading="loading"
        :search="searchActivation"
        item-key="location"
        class="elevation-1"
        disable-initial-sort
      >
        <template #items="props">
          <tr
            :active="props.selected"
            @click="props.expanded = !props.expanded"
          >
            <td class="text-xs-left">
              {{ props.item.location }}
            </td>
            <td class="text-xs-center">
              {{ props.item.Prepaid }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B10 }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B39 }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B49 }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B39_PLUS20 }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B39_PLUS50 }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B59E }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B59DB }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B89DB }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B54DB }}
            </td>
            <td class="text-xs-center">
              {{ props.item.NU }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B150 }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B39_FC }}
            </td>
            <td class="text-xs-center">
              {{ props.item.tbg50 }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B54DB_FC }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B59DB_FC }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B89DB_FC }}
            </td>
            <td class="text-xs-center">
              {{ props.item.B150_FC }}
            </td>
            <td class="text-xs-center">
              {{ props.item.onefmain }}
            </td>
            <td class="text-xs-center">
              {{ props.item.onefsub }}
            </td>
            <td class="text-xs-center">
              {{ props.item.mysiswa35 }}
            </td>
            <td class="text-xs-center primary">
              {{ props.item.total }}
            </td>
          </tr>
        </template>
        <template #expand="props">
          <v-sheet color="grey">
            <v-container>
              <v-layout row>
                <v-flex x12>
                  <v-data-table
                    :headers="detailActivationHeaders"
                    :items="props.item.items"
                    item-key="dealer"
                    class="elevation-1"
                  >
                    <template #items="dealerProps">
                      <tr>
                        <td class="text-xs-left">
                          {{ dealerProps.item.dealer }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.Prepaid }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B10 }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B39 }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B49 }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B39_PLUS20 }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B39_PLUS50 }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B59E }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B59DB }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B89DB }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B54DB }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.NU }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B150 }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B39_FC }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.tbg50 }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B54DB_FC }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B59DB_FC }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B89DB_FC }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.B150_FC }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.onefmain }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.onefsub }}
                        </td>
                        <td class="text-xs-center">
                          {{ dealerProps.item.mysiswa35 }}
                        </td>
                        <td class="text-xs-center primary">
                          {{ dealerProps.item.total }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-container>
          </v-sheet>
        </template>
        <template #footer>
          <tr>
            <td class="text-xs-right">
              Sub-total:
            </td>
            <td class="text-xs-center primary--text">
              {{ prepaid }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b10 }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b39 }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b49 }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b39plus20 }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b39plus50 }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b59e }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b59db }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b89db }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b54db }}
            </td>
            <td class="text-xs-center primary--text">
              {{ nu }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b150 }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b39_fc }}
            </td>
            <td class="text-xs-center primary--text">
              {{ tbg50 }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b54db_fc }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b59db_fc }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b89db_fc }}
            </td>
            <td class="text-xs-center primary--text">
              {{ b150_fc }}
            </td>
            <td class="text-xs-center primary--text">
              {{ onefmain }}
            </td>
            <td class="text-xs-center primary--text">
              {{ onefsub }}
            </td>
            <td class="text-xs-center primary--text">
              {{ mysiswa35 }}
            </td>
            <td class="text-xs-center primary">
              {{ totalActivation }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-layout>
        <v-flex xs3>
          <v-subheader>Reloads</v-subheader>
        </v-flex>
        <v-flex
          xs3
          offset-xs5
        >
          <v-text-field
            v-model="searchReload"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-flex>
        <v-flex xs1>
          <v-btn
            dark
            fab
            small
            color="light-green"
            @click="exportReport(reloads)"
          >
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-data-table
        :headers="reloadHeaders"
        :items="reloads"
        :expand="expand"
        :loading="loading"
        :search="searchReload"
        item-key="location"
        class="elevation-1"
        disable-initial-sort
      >
        <template #items="props">
          <tr
            :active="props.selected"
            @click="props.expanded = !props.expanded"
          >
            <td class="text-xs-left">
              {{ props.item.location }}
            </td>
            <td class="text-xs-center primary">
              {{ props.item.total }}
            </td>
          </tr>
        </template>
        <template #expand="props">
          <v-sheet color="grey">
            <v-container>
              <v-layout row>
                <v-flex x12>
                  <v-data-table
                    :headers="detailReloadHeaders"
                    :items="props.item.items"
                    item-key="dealer"
                    class="elevation-1"
                  >
                    <template #items="dealerProps">
                      <tr>
                        <td class="text-xs-left">
                          {{ dealerProps.item.dealer }}
                        </td>
                        <td class="text-xs-center primary">
                          {{ dealerProps.item.reload }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-container>
          </v-sheet>
        </template>
        <template #footer>
          <tr>
            <td class="text-xs-right">
              Total:
            </td>
            <td class="text-xs-center primary">
              {{ Number(totalReload).toFixed(2) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-layout>
        <v-flex xs3>
          <v-subheader>Recruitments</v-subheader>
        </v-flex>
        <v-flex
          xs3
          offset-xs5
        >
          <v-text-field
            v-model="searchRecruitment"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-flex>
        <v-flex xs1>
          <v-btn
            dark
            fab
            small
            color="light-green"
            @click="exportReport(recruitments)"
          >
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-data-table
        :headers="recruitmentHeaders"
        :items="recruitments"
        :expand="expand"
        :loading="loading"
        :search="searchRecruitment"
        item-key="location"
        class="elevation-1"
        disable-initial-sort
      >
        <template #items="props">
          <tr
            :active="props.selected"
            @click="props.expanded = !props.expanded"
          >
            <td class="text-xs-left">
              {{ props.item.location }}
            </td>
            <td class="text-xs-center primary">
              {{ props.item.total }}
            </td>
          </tr>
        </template>
        <template #expand="props">
          <v-sheet color="grey">
            <v-container>
              <v-layout row>
                <v-flex x12>
                  <v-data-table
                    :headers="detailRecruitmentHeaders"
                    :items="props.item.items"
                    item-key="dealer"
                    class="elevation-1"
                  >
                    <template #items="dealerProps">
                      <tr>
                        <td class="text-xs-left">
                          {{ dealerProps.item.dealer }}
                        </td>
                        <td class="text-xs-center primary">
                          {{ dealerProps.item.recruitment }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-container>
          </v-sheet>
        </template>
        <template #footer>
          <tr>
            <td class="text-xs-right">
              Total:
            </td>
            <td class="text-xs-center primary">
              {{ totalRecruitment }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-layout>
        <v-flex xs3>
          <v-subheader>Schedule</v-subheader>
        </v-flex>
        <v-flex
          xs3
          offset-xs5
        >
          <v-text-field
            v-model="searchSchedule"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-flex>
        <v-flex xs1>
          <v-btn
            dark
            fab
            small
            color="light-green"
            @click="exportReport(schedules)"
          >
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-data-table
        :headers="scheduleHeaders"
        :items="schedules"
        :expand="expand"
        :loading="loading"
        :search="searchSchedule"
        item-key="location"
        class="elevation-1"
        disable-initial-sort
      >
        <template #items="props">
          <tr
            :active="props.selected"
            @click="props.expanded = !props.expanded"
          >
            <td class="text-xs-left">
              {{ props.item.location }}
            </td>
            <td class="text-xs-center primary">
              {{ props.item.total }}
            </td>
          </tr>
        </template>
        <template #expand="props">
          <v-sheet color="grey">
            <v-container>
              <v-layout row>
                <v-flex x12>
                  <v-data-table
                    :headers="detailScheduleHeaders"
                    :items="props.item.items"
                    item-key="dealer"
                    class="elevation-1"
                  >
                    <template #items="dealerProps">
                      <tr>
                        <td class="text-xs-left">
                          {{ dealerProps.item.dealer }}
                        </td>
                        <td class="text-xs-left">
                          {{ dealerProps.item.msisdn }}
                        </td>
                        <td class="text-xs-left">
                          {{ dealerProps.item.name }}
                        </td>
                        <td class="text-xs-left">
                          {{ dealerProps.item.rank }}
                        </td>
                        <td class="text-xs-center">
                          <v-chip
                            :color="dealerProps.item.workingType === 'Full-time' ? 'success' : 'success darken-3'"
                            text-color="white"
                          >
                            {{ dealerProps.item.workingType }}
                          </v-chip>
                        </td>
                        <td class="text-xs-center">
                          <v-chip
                            :color="dealerProps.item.status === 'Active' ? 'success' : (dealerProps.item.status === 'Demoted' ? 'warning' : 'error')"
                            text-color="white"
                          >
                            {{ dealerProps.item.status }}
                          </v-chip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-container>
          </v-sheet>
        </template>
      </v-data-table>
    </wide-panel>
  </v-scale-transition>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'
import XLSX from 'xlsx'

const pageTitle = 'X-Force Daily Report'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      title: pageTitle,
      date: DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL-dd'),
      today: DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL-dd'),
      dateMenu: false,
      loading: false,
      expand: false,
      searchActivation: null,
      activations: [],
      searchReload: null,
      reloads: [],
      searchRecruitment: null,
      recruitments: [],
      searchSchedule: null,
      schedules: [],
      activationHeaders: [
        {
          text: 'Location',
          align: 'left',
          sortable: true,
          value: 'location',
        },
        {
          text: 'Prepaid',
          align: 'center',
          sortable: true,
          value: 'Prepaid',
        },
        {
          text: 'B10',
          align: 'center',
          sortable: true,
          value: 'B10',
        },
        {
          text: 'B39',
          align: 'center',
          sortable: true,
          value: 'B39',
        },
        {
          text: 'B49',
          align: 'center',
          sortable: true,
          value: 'B49',
        },
        {
          text: 'B39_PLUS20',
          align: 'center',
          sortable: true,
          value: 'B39_PLUS20',
        },
        {
          text: 'B39_PLUS50',
          align: 'center',
          sortable: true,
          value: 'B39_PLUS50',
        },
        {
          text: 'B59E',
          align: 'center',
          sortable: true,
          value: 'B59E',
        },
        {
          text: 'B59DB',
          align: 'center',
          sortable: true,
          value: 'B59DB',
        },
        {
          text: 'B89DB',
          align: 'center',
          sortable: true,
          value: 'B89DB',
        },
        {
          text: 'B54DB',
          align: 'center',
          sortable: true,
          value: 'B54DB',
        },
        {
          text: 'NU',
          align: 'center',
          sortable: true,
          value: 'NU',
        },
        {
          text: 'B150',
          align: 'center',
          sortable: true,
          value: 'B150',
        },
        {
          text: 'B39_FC',
          align: 'center',
          sortable: true,
          value: 'B39_FC',
        },
        {
          text: 'TBG50',
          align: 'center',
          sortable: true,
          value: 'tbg50',
        },
        {
          text: 'B54DB_FC',
          align: 'center',
          sortable: true,
          value: 'B54DB_FC',
        },
        {
          text: 'B59DB_DC',
          align: 'center',
          sortable: true,
          value: 'B59DB_FC',
        },
        {
          text: 'B89DB_FC',
          align: 'center',
          sortable: true,
          value: 'B89DB_FC',
        },
        {
          text: 'B150_FC',
          align: 'center',
          sortable: true,
          value: 'B150_FC',
        },
        {
          text: 'ONEFamily Main',
          align: 'center',
          sortable: true,
          value: 'onefmain',
        },
        {
          text: 'ONEFamily Sub',
          align: 'center',
          sortable: true,
          value: 'onefsub',
        },
        {
          text: 'MYSISWA35',
          align: 'center',
          sortable: true,
          value: 'mysiswa35',
        },
        {
          text: 'Total',
          align: 'center',
          sortable: true,
          value: 'total',
        },
      ],
      detailActivationHeaders: [
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Prepaid',
          align: 'center',
          sortable: true,
          value: 'Prepaid',
        },
        {
          text: 'B10',
          align: 'center',
          sortable: true,
          value: 'B10',
        },
        {
          text: 'B39',
          align: 'center',
          sortable: true,
          value: 'B39',
        },
        {
          text: 'B49',
          align: 'center',
          sortable: true,
          value: 'B49',
        },
        {
          text: 'B39_PLUS20',
          align: 'center',
          sortable: true,
          value: 'B39_PLUS20',
        },
        {
          text: 'B39_PLUS50',
          align: 'center',
          sortable: true,
          value: 'B39_PLUS50',
        },
        {
          text: 'B59E',
          align: 'center',
          sortable: true,
          value: 'B59E',
        },
        {
          text: 'B59DB',
          align: 'center',
          sortable: true,
          value: 'B59DB',
        },
        {
          text: 'B89DB',
          align: 'center',
          sortable: true,
          value: 'B89DB',
        },
        {
          text: 'B54DB',
          align: 'center',
          sortable: true,
          value: 'B54DB',
        },
        {
          text: 'NU',
          align: 'center',
          sortable: true,
          value: 'NU',
        },
        {
          text: 'B150',
          align: 'center',
          sortable: true,
          value: 'B150',
        },
        {
          text: 'B39_FC',
          align: 'center',
          sortable: true,
          value: 'B39_FC',
        },
        {
          text: 'TBG50',
          align: 'center',
          sortable: true,
          value: 'tbg50',
        },
        {
          text: 'B54DB_FC',
          align: 'center',
          sortable: true,
          value: 'B54DB_FC',
        },
        {
          text: 'B59DB_DC',
          align: 'center',
          sortable: true,
          value: 'B59DB_FC',
        },
        {
          text: 'B89DB_FC',
          align: 'center',
          sortable: true,
          value: 'B89DB_FC',
        },
        {
          text: 'B150_FC',
          align: 'center',
          sortable: true,
          value: 'B150_FC',
        },
        {
          text: 'ONEFamily Main',
          align: 'center',
          sortable: true,
          value: 'onefmain',
        },
        {
          text: 'ONEFamily Sub',
          align: 'center',
          sortable: true,
          value: 'onefsub',
        },
        {
          text: 'MYSISWA35',
          align: 'center',
          sortable: true,
          value: 'mysiswa35',
        },
        {
          text: 'Total',
          align: 'center',
          sortable: true,
          value: 'total',
        },
      ],
      reloadHeaders: [
        {
          text: 'Location',
          align: 'left',
          sortable: true,
          value: 'location',
        },
        {
          text: 'Total',
          align: 'center',
          sortable: true,
          value: 'total',
        },
      ],
      detailReloadHeaders: [
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Reload',
          align: 'center',
          sortable: true,
          value: 'reload',
        },
      ],
      recruitmentHeaders: [
        {
          text: 'Location',
          align: 'left',
          sortable: true,
          value: 'location',
        },
        {
          text: 'Total',
          align: 'center',
          sortable: true,
          value: 'total',
        },
      ],
      detailRecruitmentHeaders: [
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Recruitment',
          align: 'center',
          sortable: true,
          value: 'recruitment',
        },
      ],
      scheduleHeaders: [
        {
          text: 'Location',
          align: 'left',
          sortable: true,
          value: 'location',
        },
        {
          text: 'Checked-in',
          align: 'center',
          sortable: true,
          value: 'total',
        },
      ],
      detailScheduleHeaders: [
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Msisdn',
          align: 'left',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Rank',
          align: 'left',
          sortable: true,
          value: 'rank',
        },
        {
          text: 'Type',
          align: 'center',
          sortable: true,
          value: 'workingType',
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'status',
        },
      ],
      prepaid: 0,
      b10: 0,
      b39: 0,
      b49: 0,
      b39plus20: 0,
      b39plus50: 0,
      b59e: 0,
      b59db: 0,
      b89db: 0,
      b54db: 0,
      nu: 0,
      b150: 0,
      b39_db: 0,
      tbg50: 0,
      b54db_fc: 0,
      b59db_fc: 0,
      b89db_fc: 0,
      b150_fc: 0,
      onefmain: 0,
      onefsub: 0,
      mysiswa35: 0,
      totalActivation: 0,
      totalReload: 0,
      totalRecruitment: 0,
      totalCheckedIn: 0,
    }
  },
  created: function () {
    this.getXForceDailyLocationReport(this.date)
  },
  watch: {
    date: function (date) {
      this.getXForceDailyLocationReport(this.date)
    },
  },
  methods: {
    getXForceDailyLocationReport: async function (date) {
      const params = createGetParams({ date })
      this.loading = true
      this.resetTotals()
      try {
        const response = await this.$rest.get('getXForceDailyLocationReport.php', params)
        response.data.activations.forEach(activation => {
          this.prepaid += activation.Prepaid
          this.b10 += activation.B10
          this.b39 += activation.B39
          this.b49 += activation.B49
          this.b39plus20 += activation.B39_PLUS20
          this.b39plus50 += activation.B39_PLUS50
          this.b59e += activation.B59E
          this.b59db += activation.B59DB
          this.b89db += activation.B89DB
          this.b54db += activation.B54DB
          this.nu += activation.NU
          this.b150 += activation.B150
          this.b39_fc += activation.B39_FC
          this.tbg50 += activation.tbg50
          this.b54db_fc += activation.B54DB_FC
          this.b59db_fc += activation.B59DB_FC
          this.b89db_fc += activation.B89DB_FC
          this.b150_fc += activation.B150_FC
          this.onefmain += activation.onefmain
          this.onefsub += activation.onefsub
          this.mysiswa35 += activation.mysiswa35
          this.totalActivation += activation.total
        })
        response.data.reloads.forEach(reload => {
          this.totalReload += reload.total * 1
        })
        response.data.recruitments.forEach(recruitment => {
          this.totalRecruitment += recruitment.total
        })
        response.data.schedules.forEach(schedule => {
          this.totalCheckedIn += schedule.total
        })
        this.activations = response.data.activations
        this.reloads = response.data.reloads
        this.recruitments = response.data.recruitments
        this.schedules = response.data.schedules
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    resetTotals: function () {
      this.activations = this.reloads = this.recruitments = this.schedules = []
      this.prepaid = 0
      this.b10 = 0
      this.b39 = 0
      this.b49 = 0
      this.b39plus20 = 0
      this.b39plus50 = 0
      this.b59e = 0
      this.b59db = 0
      this.b89db = 0
      this.b54db = 0
      this.nu = 0
      this.b150 = 0
      this.b39_fc = 0
      this.tbg50 = 0
      this.b54db_fc = 0
      this.b59db_fc = 0
      this.b89db_fc = 0
      this.b150_fc = 0
      this.onefmain = 0
      this.onefsub = 0
      this.mysiswa35 = 0
      this.totalActivation = this.totalReload = this.totalRecruitment = this.totalCheckedIn = 0
    },
    exportReport: function (object) {
      const locationColumn = 0
      const data = []
      const merges = []
      let index = 1

      object.forEach(obj => {
        const merge = {
          s: {
            r: index,
            c: locationColumn,
          },
          e: {
            r: index,
            c: locationColumn,
          },
        }
        let firstRow = true
        obj.items.forEach(item => {
          let row = Array(1)

          if (firstRow) {
            row[0] = obj.location
            firstRow = false
          } else {
            merge.e.r = index
          }
          row = row.concat(Object.values(item))
          data.push(row)
          index++
        })
        merge.e.r > merge.s.r && merges.push(merge)
      })

      let filename
      if (object === this.activations) {
        filename = 'activations'
        data.unshift(['Location', 'Dealer', 'Prepaid', 'B10', 'B39', 'B49', 'B39_PLUS20', 'B39_PLUS50', 'B59E', 'B59DB', 'B89DB', 'B54DB', 'NU', 'B150', 'B39_FC', 'B54DB_FC', 'B59DB_FC', 'B89DB_FC', 'B150_FC', 'ONEFamily Main', 'ONEFamily Sub', 'MYSISWA35', 'Total'])
      } else if (object === this.reloads) {
        filename = 'reloads'
        data.unshift(['Location', 'Dealer', 'Reload'])
      } else if (object === this.recruitments) {
        filename = 'recruitments'
        data.unshift(['Location', 'Dealer', 'Recruitment'])
      } else {
        filename = 'schedule'
        data.unshift(['Location', 'Dealer', 'Msisdn', 'Name', 'Rank', 'Type', 'Status'])
      }
      const ws = XLSX.utils.aoa_to_sheet(data)
      const wb = XLSX.utils.book_new()
      if (merges.length > 0) {
        ws['!merges'] = merges
      }
      XLSX.utils.book_append_sheet(wb, ws, this.date)
      XLSX.writeFile(wb, 'xforce-' + filename + '-' + this.date + '.xlsx')
    },
  },
}
</script>
